import React from "react";
import ResponsiveAppBar from "../../components/NavigationBar/AppBar";
import Footer from "../../components/Footer/Footer";
import HeroSection from "../../components/Home/HeroSection";
// import OurClients from "../../components/OurClients/OurClients";
import OfferingsContainer from "../../components/ourOfferings/OurOfferings";
import Stats from "../../components/Stats/Stats";
import WhyChooseUs from "../../components/Home/whyChooseus/WhyChooseUs";
import TechnologySection from "../../components/Home/TechnologySection";
import PremierRecruitmentSection from "../../components/Home/PremierRecruitmentSection";
import ContactForm from "../../components/ContactUs/ContactForm";
import IndiaBanner from "../../components/Home/IndiaBanner";

const Home = () => {
  return (
    <>
      <ResponsiveAppBar />
      <HeroSection />
      {/* <OurClients /> */}
      <OfferingsContainer />
      <Stats />
      <WhyChooseUs />
      <TechnologySection />
      <PremierRecruitmentSection />
      <ContactForm />
      <IndiaBanner />
      <Footer />
    </>
  );
};

export default Home;
