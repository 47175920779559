import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import offeringImage from "../../assets/offerings.png";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";

const OfferingsContainer = () => {
  const offeringDetails = [
    {
      title: "Tailored Recruitment Solutions for FMCG",
      description:
        "Custom hiring services for roles in sales, marketing, supply chain, production, R&D, and more.",
      icon: icon1,
    },
    {
      title: "Advanced Talent Acquisition Tools",
      description:
        "AI-driven platforms and data analytics ensure precise, efficient, and innovative hiring processes.",
      icon: icon2,
    },
    {
      title: "Consultative Workforce Planning",
      description:
        "Strategic insights to align your talent strategy with long-term business objectives, ensuring a future-ready workforce.",
      icon: icon2,
    },
    {
      title: "End-to-End Recruitment Support",
      description:
        "From candidate sourcing to onboarding, we handle every step of the recruitment process with expertise and precision",
      icon: icon3,
    },
  ];

  return (
    <Container sx={{ maxWidth: "xl" }}>
      <Box
        sx={{
          textAlign: "center",
          padding: { xs: 2, md: 3 },
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          color="#603890"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.8rem", sm: "3rem" }, // Responsive font size
          }}
        >
          Our Offerings
        </Typography>

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "space-between" },
            gap: 2,
            flexWrap: "wrap",
            marginTop: 4,
          }}
        >
          {[
            "Sales Manager",
            "Sales Manager",
            "Sales Manager",
            "Sales Manager",
            "Sales Manager",
          ].map((role, index) => (
            <Button
              key={index}
              sx={{
                padding: { xs: "8px 16px", md: "10px 20px" },
                backgroundColor: "#603890",
                color: "white",
                "&:hover": {
                  backgroundColor: "#7a4aa3",
                },
                borderRadius: "50px",
                fontSize: { xs: "0.8rem", sm: "1rem" },
              }}
            >
              {role}
            </Button>
          ))}
        </Box> */}

        <Grid
          container
          spacing={4}
          sx={{
            marginTop: 5,
          }}
        >
          {/* Left side: 4 Cards */}
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              {offeringDetails.map((offering, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box
                    sx={{
                      height: "180px",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "8px",
                      padding: 2,
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      boxShadow: "0 4px 10px rgba(96, 56, 144, 0.8)", // Colored shadow
                      marginTop: "10px"
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: -10,
                        left: 20,
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#603890",
                        color: "white",
                        borderRadius: "10%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                       <img
                        src={offering.icon}
                        alt={offering.title}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: 1,
                        fontSize: { xs: "1rem", md: "1.2rem" },
                      }}
                    >
                      {offering.title}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                      {offering.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Right side: Image */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "250px", md: "400px" },
                backgroundImage: `url(${offeringImage})`,
                backgroundSize: "contain", // Ensures image scales properly
                backgroundRepeat: "no-repeat", // Prevent repeating
                backgroundPosition: "center",
                borderRadius: "8px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OfferingsContainer;
