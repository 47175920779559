import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/aboutUs/AboutUs";
import ContactUs from "../pages/Contact/ContactUs";
import Offerings from "../pages/Offerings/Offerings";

const RoutingComponent = () => {
  return (
    <Router>
      <Routes>

      <Route path="/" element={<Home />} />

        {/* Home */}
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/offerings" element={<Offerings/>} />
      
      </Routes>
    </Router>
  );
};

export default RoutingComponent;
