// Offerings.js
import React from "react";
import ResponsiveAppBar from "../../components/NavigationBar/AppBar";
import OfferingsContainer from "../../components/ourOfferings/OurOfferings"; // Adjust the import path if needed
import Footer from "../../components/Footer/Footer";

const Offerings = () => {
  return (
    <>
      <ResponsiveAppBar />
        <OfferingsContainer />
      <Footer />
    </>
  );
};

export default Offerings;
