import './App.css';
import RoutingComponent from './routes/routes';

function App() {
  return (
    <RoutingComponent />
  );
}

export default App;
